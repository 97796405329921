<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-organization-list' }">Organizations</router-link> &gt;
                    <router-link :to="{ name: 'organization-dashboard', params: { organizationId: this.$route.params.organizationId } }">{{ organizationName }}</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ organizationName }}</h1>
                <p class="text-caption text-center">Users</p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <UserList/>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
// import { mapState } from 'vuex';
import UserList from '@/components/organization-dashboard/UserList.vue';

export default {
    components: {
        UserList,
    },
    data: () => ({
        organization: null,
        error: null,
    }),
    computed: {
        // ...mapState({
        //     session: (state) => state.session,
        // }),
        organizationName() {
            return this.organization?.name ?? 'Unknown';
        },
    },
    methods: {
        async loadOrganization() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadOrganization: true });
                const response = await this.$client.organization(this.$route.params.organizationId).currentOrganization.get();
                console.log(`organization/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.organization = response;
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load organization', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadOrganization: false });
            }
        },
    },
    mounted() {
        this.loadOrganization();
    },
};
</script>
