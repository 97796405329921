<template>
    <div>
        <template v-if="link">
            <router-link :to="{ name: 'organization-view-user', params: { organizationId: this.$route.params.organizationId, userId: id } }">
                <template v-if="displayName">
                    {{ displayName }}
                </template>
                &lt;{{ email }}&gt;
            </router-link>
        </template>
        <template v-if="!link">
            <template v-if="displayName">
                {{ displayName }}
            </template>
            &lt;{{ email }}&gt;
        </template>
        <p class="text-caption">{{ id }}</p>
    </div>
</template>

<script>
export default {

    props: {
        // when true, the item is a link to the user view
        link: {
            type: Boolean,
            default: false,
        },
        organizationId: {
            type: String,
        },
        attr: {
            type: Object,
        },
    },

    computed: {
        id() {
            return this.attr.id;
        },
        email() {
            return this.attr.email;
        },
        displayName() {
            return this.attr.displayName;
        },
    },

};
</script>
